import { useEffect, useMemo, useState } from "react";
import { X } from "react-feather";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import Container from "../../components/Container";
import Loader from "../../components/Loader";
import NewsSection from "../../components/NewsSection";
import InvestmentsService from "../../services/InvestmentsService";
import { Dropdown, Pagination } from "semantic-ui-react";
import AreaDropdown from "../Search/components/Flat/SearchComponents/AreaDropdown";
import DistrictDropdown from "../Search/components/Flat/SearchComponents/DistrictDropdown";
import PriceDropdown from "../Search/components/Flat/SearchComponents/PriceDropdown";
import RoomsDropdown from "../Search/components/Flat/SearchComponents/RoomsDropdown";
import AdvancedDropdown from "../Search/components/Flat/SearchComponents/AdvancedDropdown";
import TypeDropdown from "../Search/components/Flat/SearchComponents/TypeDropdown";
import {
  DefaultValueInput,
  DefaultValueInputText,
  DistrictValueInput,
} from "../Search/components/Flat/SearchComponents/ValueInputs";

import LocationsServices from "../../services/LocationsServies";
import {
  convertValuesToURL,
  DEFAULTVALUES,
  getParamsToApi,
  parseURLtoFormValues,
} from "../Search/searchUtils";
import { useStoreActions, useStoreState } from "easy-peasy";

import MapInvestments from "./components/MapInvestments";
import FlatList from "./components/FlatList";
import {
  getAdvancedPlaceholder,
  getAreaPlaceholder,
  getPricePlaceholder,
  getRoomsPlaceholder,
  getTypePlaceholder,
} from "../Search/getPlaceholders";

const Investments = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [activeLoader, setActiveLoader] = useState(true);
  const [sortType, setSortType] = useState("price_up");
  const searchFilters = useStoreState((state) => state.filters.searchFilters);
  const setSearchFilters = useStoreActions(
    (state) => state.filters.setSearchFilters
  );

  useEffect(() => {
    if (searchFilters) {
      history.push(`/investments?${convertValuesToURL(searchFilters)}`);
      for (const [key, value] of Object.entries(searchFilters)) {
        setValue(key, value);
      }
    }
  }, []);

  const [pagination, setPagination] = useState({
    data: [],
    offset: 0,
    numberPerPage: 10,
    pageCount: 1,
  });

  const [resetQuery, setResetQuery] = useState(false);
  const { handleSubmit, watch, control, setValue } = useForm({
    defaultValues: parseURLtoFormValues(location.search),
  });
  const [onWheelMouse, setOnWheelMouse] = useState(false);
  const completion_quarters = useStoreState(
    (state) => state.dictionaries.dictionariesList.completion_quarters
  );
  useEffect(() => {
    setOnWheelMouse(false);
  }, [onWheelMouse]);

  const values = watch();

  const { isLoading: appIsLoading } = useStoreState((state) => state.isLoading);

  const { data: locationsData } = useQuery(
    [values.type],
    LocationsServices.getLocations(values.type)
  );

  const handleReset = () => {
    if (resetQuery === false) setResetQuery(!resetQuery);
    Object.entries(DEFAULTVALUES)?.forEach(([key, value]) => {
      setValue(key, value);
    });
    setSearchFilters(DEFAULTVALUES);
    history.push(`/investments/?${convertValuesToURL(DEFAULTVALUES)}`);
  };

  const handleSetQuery = (value) => {
    setResetQuery(value);
  };

  const handlePageClick = (event, { activePage }) => {
    window.scrollTo(0, 0);
    setSearchFilters({ ...searchFilters, page: [activePage] });
    history.push(
      `/investments?${convertValuesToURL({
        ...searchFilters,
        page: [activePage],
      })}`
    );
  };
  const handleSortAsc = (arr) => {
    return arr.sort((a, b) => (a.value > b.value ? 1 : -1));
  };
  const cities = useMemo(() => {
    if (!locationsData) return [];
    const result = locationsData.map(
      ({
        city_name,
        voivodeship_name,
        quarter_name,
        type,
        voivodeship_id,
        city_id,
        quarter_id,
      }) => {
        switch (type) {
          case "voivodeship":
            return {
              value: voivodeship_name.toLowerCase(),
              name: voivodeship_name,
              description: `Całe województwo`,
              id: `v:${voivodeship_id}`,
              type: type,
            };
          case "city":
            return {
              value: city_name.toLowerCase(),
              name: city_name,
              description: `${voivodeship_name}`,
              id: `c:${city_id}`,
              type: type,
            };
          case "quarter":
            return {
              value: quarter_name.toLowerCase(),
              name: quarter_name,
              description: city_name,
              id: `q:${quarter_id}`,
              type: type,
            };
        }
      }
    );
    const voivodeships = handleSortAsc(
      result?.filter((el) => el.type === "voivodeship")
    );
    const citys = handleSortAsc(result?.filter((el) => el.type === "city"));
    const quarters = handleSortAsc(
      result?.filter((el) => el.type === "quarter")
    );

    return [...citys, ...quarters, ...voivodeships];
  }, [locationsData]);

  const filterInvestments = (data) => {
    if (!data) return null;

    setPagination((prevState) => ({
      ...prevState,
      pageCount: Math.ceil(
        data?.paginator.totalItems / data.paginator.itemsPerPage
      ),
      data: data.payload,
      offset: data.paginator.totalItems,
    }));

    setTimeout(() => {
      setActiveLoader(false);
    }, 200);
  };

  const searchNewData = async () => {
    setActiveLoader(true);
    await InvestmentsService.getInvestments(
      getParamsToApi(parseURLtoFormValues(location.search)),
      1,
      pagination.numberPerPage,
      true
    ).then((res) => {
      setTimeout(() => {
        filterInvestments(res);
      }, 300);
    });
  };

  useEffect(() => {
    if (
      location.search ||
      location?.search?.slice(1) === convertValuesToURL(searchFilters)
    ) {
      searchNewData();
    }
  }, [location.search]);

  const onSubmit = (values) => {
    setSearchFilters(values);
    history.push(`/investments/?${convertValuesToURL(values)}`);
    return values;
  };

  const handleSort = (event, dataEvent) => {
    if (pagination.data) {
      if (dataEvent?.value) setSortType(dataEvent?.value);
    }
  };

  const optionsSort = [
    {
      key: `${t("Price")} ${t("ascending")}`,
      text: `${t("Price")} ${t("ascending")}`,
      value: "price_up",
    },
    {
      key: `${t("Price")} ${t("descending")}`,
      text: `${t("Price")} ${t("descending")}`,
      value: "price_down",
    },
    {
      key: `${t("Price")} ${t("per m2")} ${t("ascending")}`,
      text: `${t("Price")} ${t("per m2")} ${t("ascending")}`,
      value: "price_per_m_up",
    },
    {
      key: `${t("Price")} ${t("per m2")} ${t("descending")}`,
      text: `${t("Price")} ${t("per m2")} ${t("descending")}`,
      value: "price_per_m_down",
    },
    {
      key: `${t("Area")} ${t("ascending")}`,
      text: `${t("Area")} ${t("ascending")}`,
      value: "area_up",
    },
    {
      key: `${t("Area")} ${t("descending")}`,
      text: `${t("Area")} ${t("descending")}`,
      value: "area_down",
    },
  ];

  return (
    <main>
      {activeLoader && <Loader />}
      {appIsLoading && <Loader />}
      <Container className="h-full flex flex-col justify-between">
        <header className=" py-4 sticky top-20 z-20 bg-white   ">
          <form
            className="items-center ml-2 xl:-ml-2  hidden lg:flex flex-wrap gap-y-2"
            onSubmit={handleSubmit(onSubmit, () => {})}
          >
            <div className="mx-2 w-72">
              <DefaultValueInputText
                placeholder={t("Enter a name of investment...")}
                control={control}
              />
            </div>
            <div className="mx-2">
              <DefaultValueInput
                placeholder={getTypePlaceholder(values)}
                control={control}
                Modal={TypeDropdown}
              />
            </div>
            <div className="mx-2">
              <DistrictValueInput
                placeholder={getTypePlaceholder(values)}
                control={control}
                cities={cities}
                Modal={DistrictDropdown}
                resetQuery={resetQuery}
                handleSetQuery={handleSetQuery}
                watch={watch}
              />
            </div>
            <div className="mx-2">
              <DefaultValueInput
                placeholder={getAreaPlaceholder(values)}
                control={control}
                Modal={AreaDropdown}
              />
            </div>
            <div className="mx-2">
              <DefaultValueInput
                placeholder={getRoomsPlaceholder(values)}
                control={control}
                Modal={RoomsDropdown}
              />
            </div>
            <div className="mx-2">
              <DefaultValueInput
                placeholder={getPricePlaceholder(values)}
                control={control}
                Modal={PriceDropdown}
              />
            </div>
            <div className="mx-2 hidden lg:block">
              <DefaultValueInput
                placeholder={getAdvancedPlaceholder(values)}
                control={control}
                Modal={AdvancedDropdown}
                setValue={setValue}
                completion_quarters={completion_quarters}
              />
            </div>
            <div className="mx-2">
              <button
                className="flex items-center m-auto py-3 px-4 text-sm"
                type="button"
                onClick={handleReset}
              >
                <X size={14} className="mr-2" />
                {t("Clear")}
              </button>
            </div>
            <div className="mx-2">
              <button
                className="font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                type="submit"
              >
                {t("Search")}
              </button>
            </div>
          </form>
        </header>
        <div className="py-2 items-center justify-between hidden lg:flex">
          <div className="md:mx-2 md:my-0">
            <h3 className="text-xl font-bold">
              {t("Search results")}:{" "}
              <span className="text-primse-green">{pagination.offset}</span>
            </h3>
          </div>
          <div className="flex items-center -ml-2">
            <div className="my-2 mx-2 md:my-0 flex  items-center hidden lg:inline-flex">
              <div>
                <p className="mx-2 text-xs lg:text-base">{t("Sort by")}: </p>
              </div>
              <div className="text-xs lg:text-base my-2 md:mx-2 md:my-0">
                <Dropdown
                  className="border-primse-green h-12"
                  style={{ display: "flex", alignItems: "center" }}
                  fluid={false}
                  selection={true}
                  defaultValue={sortType}
                  options={optionsSort}
                  value={sortType}
                  placeholder={t("Choose")}
                  onChange={(event, data) => handleSort(event, data)}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ minHeight: "80vh" }}>
          {pagination.data?.length !== 0 && (
            <>
              {pagination.data.map((investment, index) => (
                <div
                  key={`investment-${investment.uuid}-${index}`}
                  className="flex justify-between "
                >
                  <div className="w-full lg:w-3/12 xl:w-5/12">
                    <MapInvestments investmentData={investment} />
                  </div>
                  <div className="hidden lg:block lg:w-9/12 xl:w-7/12 py-5">
                    <FlatList
                      uuid={investment.uuid}
                      index={index}
                      sortType={sortType}
                      setSortType={setSortType}
                    />
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        {pagination.pageCount > 1 && (
          <div className="flex justify-center md:justify-start py-4">
            <Pagination
              activePage={searchFilters?.page}
              totalPages={pagination.pageCount}
              onPageChange={handlePageClick}
            />
          </div>
        )}
      </Container>
      <NewsSection />
    </main>
  );
};

export default Investments;
