import { useState, useMemo } from "react";
import { useQuery } from "react-query";
import { Link, useParams, useHistory } from "react-router-dom";
import { Element, Link as SmoothLink } from "react-scroll";
import { Modal, Popup } from "semantic-ui-react";
import cs from "classnames";
import Button from "../../components/Button";
import Container from "../../components/Container";
import FlatList from "../../components/FlatList";
import Gallery from "../../components/Gallery";
import Infobox from "../../components/InfoBox";
import { CartWhite, MapPin } from "../../components/Icons";
import InvestmentsService from "../../services/InvestmentsService";
import Description from "./components/Description";
import Documents from "./components/Documents";
import AgentInformation from "../../components/AgentInformation";

import FlatListMobile from "../../components/FlatListMobile";
import PopupTrigger from "../../components/PopupTrigger";
import BreadcrumbExample from "../../components/Breadcrumb";
import NewsSection from "../../components/NewsSection";
import Timeline from "../../components/Timeline";
import { useTranslation } from "react-i18next";
import { useStoreState, useStoreActions } from "easy-peasy";
import Loader from "../../components/Loader";
import ButtonAddToBriefcase from "./components/ButtonAddToBriefcase";
import { ArrowRight, X } from "react-feather";
import { actions } from "../../store";
import ClockAndTabsImage from "./components/clockTabs.png";
import { editCompletionDate } from "../../utils/searchUtils";

import InfoBoxDeveloper from "../../components/InfoBoxDeveloper";
import useUserRole from "../../hooks/useUserRole";
import OfferInvestment from "./components/OfferInvestment";
import useWindowSize from "../../hooks/useWindowSize";
import MapGoogle from "../../components/MapGoogle";
import RequestBriefcaseUser from "./components/RequestUser/RequestBriefcaseUser";

const Investment = () => {
  const [showModalMap, setShowModalMap] = useState(false);
  const [hasFactoring, setHasFactoring] = useState(false);
  const { t } = useTranslation();
  const { mode } = useStoreState((state) => state.mode);
  const userRole = useStoreState((state) => state.auth.user?.role);
  const { uuid } = useParams();
  const history = useHistory();
  const { isAgent } = useUserRole();
  const { width } = useWindowSize();

  const setProblemModalStatus = useStoreActions(
    (state) => state.modals.setProblemModalStatus
  );

  const { data, isLoading } = useQuery(
    `investment-${uuid}`,
    InvestmentsService.getInvestment(uuid)
  );

  const { data: investmentTerms } = useQuery(
    `investment-terms-${uuid}`,
    InvestmentsService.getInvestmentTerms(uuid)
  );

  const INVESTMENT_NAV = [
    {
      label: t("Gallery"),
      to: "gallery",
      offset: -70,
      mobile: true,
      deskop: true,
    },
    {
      label: t("Apartaments"),
      to: "flats",
      offset: -70 - 60,
      mobile: false,
      deskop: true,
    },
    {
      label: t("Apartaments"),
      to: "flatsMobile",
      offset: -70 - 60,
      mobile: true,
      deskop: false,
    },
    {
      label: t("Description"),
      to: "description",
      offset: -70 - 60,
      mobile: true,
      deskop: true,
    },
    {
      label: t("Map"),
      to: "map",
      offset: -70 - 60,
      mobile: true,
      deskop: true,
    },
    {
      label: t("Construction schedule"),
      to: "timeline",
      offset: -70 - 60,
      mobile: true,
      deskop: true,
    },
    {
      label: t("Documents"),
      to: "docs",
      offset: -70 - 60,
      mobile: false,
      deskop: true,
    },
  ];

  if (mode !== "presentation") {
    INVESTMENT_NAV.splice(6, 0, {
      label: t("Information for the agent"),
      to: "agent",
      offset: -70 - 60,
      mobile: false,
      deskop: true,
    });
  }

  const informationData = useMemo(() => {
    if (!data) return null;
    return [
      {
        label: "Completion date",
        value: `${editCompletionDate(data.completion_date, t("Quarter"))}`,
      },
      { label: "Floors", value: `${data.floors_number}` },
      {
        label: "Website",
        value: (
          <Link
            to={{
              pathname: `${
                data.www.startsWith("www")
                  ? "https://".concat(data.www)
                  : data.www
              }`,
            }}
            target="_blank"
          >
            <br />
            {data.www}
          </Link>
        ),
        display: data.www ? (mode !== "presentation" ? true : false) : false,
      },
      { label: "Stage", value: `${data.stage}` },
      {
        label: "Decor info",
        value: `${data.possibility_finishing === "1" ? "Yes" : "No"}`,
      },
      {
        label: "gas",
        value: `${
          data.has_gas !== null ? (data.has_gas === "1" ? "Yes" : "No") : null
        }`,
      },
      {
        label: "Mandatory parking space",
        value: `${
          data.is_parking_space_mandatory !== null
            ? data.is_parking_space_mandatory === "1"
              ? "Yes"
              : "No"
            : null
        }`,
      },
      {
        label: "Parking space",
        value: `${
          data.has_parking_space !== null
            ? data.has_parking_space === "1"
              ? "Yes"
              : "No"
            : null
        }`,
      },
      {
        label: "Gross price of a parking space",
        value: `${
          data.parking_space_price !== null
            ? `${data.parking_space_price} PLN`
            : null
        }`,
      },
      {
        label: "Gross price of a parking space underground",
        value: `${
          data.parking_space_underground_price !== null
            ? `${data.parking_space_underground_price} PLN`
            : null
        }`,
      },
      {
        label: "Storage",
        value: `${
          data.has_storage_room !== null
            ? data.has_storage_room === "1"
              ? "Yes"
              : "No"
            : null
        }`,
      },
      {
        label: "Gross price per square meter of a tenant's storage unit",
        value: `${
          data.storage_room_price_per_sqm !== null
            ? `${data.storage_room_price_per_sqm} PLN`
            : null
        }`,
      },
      {
        label: "Cellars",
        value: `${
          data.has_basement !== null
            ? data.has_basement === "1"
              ? "Yes"
              : "No"
            : null
        }`,
      },

      { label: "Security", value: `${data.safeguard === "1" ? "Yes" : "No"}` },

      { label: "Window type", value: `${data.window_type}` },
      { label: "Heating type", value: `${data.heating_type}` },
      { label: "Type of ownership", value: `${data.ownership_form}` },
      { label: "Access", value: `${data.access_type}` },
      {
        label: "Closed area",
        value: `${data.closed ? "Yes" : "No"}`,
      },
      {
        label: "Monitoring",
        value: `${data.monitoring === "1" ? "Yes" : "No"}`,
      },
      {
        label: "Reception/Portier",
        value: `${data.reception === "1" ? "Yes" : "No"}`,
      },
      {
        label: "Elevators in the building",
        value: `${data.is_elevators === "1" ? "Yes" : "No"}`,
      },
      {
        label: "Build technology",
        value: `${data.build_technology}`,
      },
      {
        label: "Building type",
        value: `${data.building_type}`,
      },
      {
        label: "Flats per floor",
        value:
          data.flats_per_floor_number > 0
            ? `${data.flats_per_floor_number}`
            : null,
      },
      {
        label: "Room height",
        value: `${
          data.room_height !== null && data.room_height !== ""
            ? `${data.room_height} m`
            : null
        }`,
      },
    ];
  }, [data, mode]);

  const visibleOfferInvestment = useMemo(() => {
    if (!width) return { deskop: false, mobile: false };
    if (width < 768) {
      return { deskop: false, mobile: true };
    }
    if (width > 1023) {
      return { deskop: true, mobile: false };
    }
    return { deskop: false, mobile: false };
  }, [width]);

  const map = useMemo(() => {
    if (!data?.static_map && !data?.static_map_presentation) return null;
    if (mode === "presentation") {
      return data?.static_map_presentation || data?.static_map;
    } else {
      return data?.static_map;
    }
  }, [data?.static_map, data?.static_map_presentation, mode]);

  const developerInfoData = useMemo(() => {
    if (!data || !data?.developer_uuid || mode === "presentation") return null;

    return {
      logo: data?.developer_logo,
      www: {
        display: data.developer_web_address ? true : false,
        value: (
          <Link
            to={{
              pathname: `${
                data?.developer_web_address?.startsWith("www")
                  ? "https://".concat(data?.developer_web_address)
                  : data?.developer_web_address
              }`,
            }}
            target="_blank"
          >
            {/* <p className="text-black hover:text-primse-green"> */}
            <p className="pt-6">
              {data.developer_name
                ? data.developer_name
                : data.developer_web_address}
            </p>
          </Link>
        ),
      },
      developerName: {
        display: !data.developer_web_address ? true : false,
        value: `${data.developer_name}`,
      },
    };
  }, [data, mode]);

  const contactData = useMemo(() => {
    if (!data) return null;
    if (
      !data.sales_office_phone &&
      !data.sales_office_email &&
      !data.developer_representative &&
      !data.sales_office_address
    )
      return null;

    const editLine = (el, length) => {
      if (!el || el === "" || el === null) return null;
      if (el?.length < length) {
        return el;
      } else {
        return <div>{el}</div>;
      }
    };

    return [
      {
        label: "Sales office phone",
        value: `${data.sales_office_phone}`,
      },
      {
        label: "Sales office email",
        value: editLine(data.sales_office_email, 27),
      },
      {
        label: "Developer representative",
        value: editLine(data.developer_representative, 24),
      },
      {
        label: "Sales office address",
        value: editLine(data.sales_office_address, 24),
      },
    ];
  }, [data]);

  const applicationsData = useMemo(() => {
    if (!investmentTerms) return null;

    const commisionTerms = () => {
      const arr = [];
      if (investmentTerms?.commision?.length === 0) return arr;
      const dateNow = new Date();

      Date.prototype.addHours = function (h) {
        this.setTime(this.getTime() + h * 60 * 60 * 1000);
        return this;
      };

      let fixed_commission_index = null;
      let promotional_commission_index = null;
      investmentTerms?.commision?.forEach((el, index) => {
        if (el?.fixed_commission === "0") {
          promotional_commission_index = index;
        }
        if (el?.fixed_commission === "1") {
          fixed_commission_index = index;
        }
      });

      let index = fixed_commission_index;
      let titleTextCommissionPromotional = t("Promotion commission in days");

      const commision =
        investmentTerms?.commision[promotional_commission_index]?.commision;
      const commission_promotional_valid_from =
        investmentTerms?.commision[promotional_commission_index]
          ?.commission_promotional_valid_from;
      const commission_promotional_valid_to =
        investmentTerms?.commision[promotional_commission_index]
          ?.commission_promotional_valid_to;

      if (
        commision &&
        commission_promotional_valid_from &&
        commission_promotional_valid_to &&
        new Date(commission_promotional_valid_from) < dateNow &&
        new Date(commission_promotional_valid_to).addHours(24) > dateNow
      ) {
        index = promotional_commission_index;
      }
      if (
        commision &&
        commission_promotional_valid_from &&
        new Date(commission_promotional_valid_from) < dateNow &&
        !commission_promotional_valid_to
      ) {
        index = promotional_commission_index;
        titleTextCommissionPromotional = t("Promotion commission from the day");
      }
      if (
        commision &&
        commission_promotional_valid_to &&
        new Date(commission_promotional_valid_to).addHours(24) > dateNow &&
        !commission_promotional_valid_from
      ) {
        index = promotional_commission_index;
        titleTextCommissionPromotional = t("Promotion commission to date");
      }

      let priceText = "";

      priceText +=
        `${
          investmentTerms?.commision[index]?.commision > 0
            ? investmentTerms?.commision[index]?.commision
            : 0
        }` + "% ";

      if (investmentTerms?.commision[index]?.is_gross) {
        priceText += `${
          investmentTerms?.commision[index]?.is_gross
            ? `${t("Gross")}`
            : `${t("Net")}`
        } `;
      }

      const formatTimeZoneDate = (date) => {
        const tzoffset = new Date(date).getTimezoneOffset() * 60000; //offset in milliseconds

        const localISOTime = new Date(
          new Date(date) - tzoffset
        ).toLocaleDateString("pt-PT");

        return localISOTime;
      };

      const dateArr = [];
      if (
        investmentTerms?.commision?.length > 1 &&
        index === promotional_commission_index
      ) {
        if (commission_promotional_valid_from)
          dateArr.push(
            formatTimeZoneDate(commission_promotional_valid_from).replaceAll(
              "/",
              "."
            )
          );
        if (commission_promotional_valid_to)
          dateArr.push(
            formatTimeZoneDate(commission_promotional_valid_to).replaceAll(
              "/",
              "."
            )
          );
      }
      setHasFactoring(
        investmentTerms?.commision[index]?.factoring ? true : false
      );
      arr.push(
        {
          label: t("Commission amount"),
          value: priceText,
        },
        {
          label: titleTextCommissionPromotional,
          value:
            dateArr?.length > 0
              ? `${dateArr?.filter((el) => el).join(" - ")}`
              : null,
        },
        {
          label: "Faktoring",
          value: `${
            investmentTerms?.commision[index]?.factoring
              ? `${t("Yes")}`
              : `${t("No")}`
          }`,
        },
        {
          label: t("Commission commentary"),
          value: `${
            investmentTerms?.commision[index]?.comment
              ? investmentTerms?.commision[index]?.comment
              : null
          }`,
        }
      );

      return arr;
    };

    const dataModified = {
      label: "Price list updated",
      value: `${data?.price_list_updated ? data?.price_list_updated : null}`,
    };

    return [
      ...commisionTerms(),
      {
        label: t("Salary conditions"),
        value: investmentTerms?.invoice?.toString(),
      },
      {
        label: t("Garage/storage room included in the commission"),
        value: `${
          investmentTerms.main.agreement_cell_payment
            ? `${t("Yes")}`
            : `${t("No")}`
        } / ${
          investmentTerms.main.agreement_garage_payment
            ? `${t("Yes")}`
            : `${t("No")}`
        }`,
      },
      {
        label: t("Commission payment deadline (days)"),
        value: `${investmentTerms?.main?.agreement_payment_days}`,
      },
      {
        label: t("Request validity (days)"),
        value:
          data?.query_expire_limited === "1"
            ? data?.query_expire_days
              ? data?.query_expire_days
              : null
            : null,
      },
      {
        label: "Publication agreement",
        value: `${
          investmentTerms?.main?.publication_agreement_type === "yes"
            ? `${t("Yes")}`
            : `${t("No")}`
        }`,
      },
      {
        label: "Mortgage register number",
        value: `${investmentTerms?.main?.mortage_register_number}`,
      },
      dataModified,
      {
        label: "Max duration of realestate oral reservation",
        value: `${investmentTerms?.main?.max_realestate_reservation_days}`,
      },
    ];
  }, [investmentTerms, data, t]);

  const BREADCRUMB_DATA = useMemo(() => {
    if (!data) return null;
    return [
      {
        name: "Main page",
        linkPage: "/",
      },
      {
        name: `${data.city}`,
        linkPage: `?location=${data.city}`,
      },
      {
        name: `${data.address_district}`,
        linkPage: `?location=${data.address_district}`,
      },
    ];
  }, [data]);

  const promotingAgencyOffer =
    data?.promoting_agency_offer == "1" ? true : false;

  return (
    <main className="mt-4">
      {isLoading && <Loader />}
      {data && data?.investment_status === "On sale" && (
        <div>
          <div className="-mb-1">
            <BreadcrumbExample
              pages={BREADCRUMB_DATA}
              returnPage={"/"}
              current={
                mode !== "presentation"
                  ? [data?.name].filter((value) => value).join(" ")
                  : null
              }
            />
          </div>
          <Container>
            <header className="px-8 md:px-0 py-1 flex items-center justify-between ">
              <div className="flex flex-col whitespace-nowrap mx-5">
                {mode !== "presentation" ? (
                  <h2 className="text-3xl font-bold m-0">{data.name}</h2>
                ) : null}

                <h3 className="text-lg m-0 font-normal">
                  {[data.city, data.adress_district, data.address_street]
                    .filter((value) => value)
                    .join(", ")}
                </h3>
                {mode !== "presentation" && promotingAgencyOffer ? (
                  <p className="text-lg mb-0 uppercase">
                    {t("Offer of promoting agency")}
                  </p>
                ) : null}
              </div>

              <div className="flex flex-wrap justify-end gap-4 items-center">
                {hasFactoring && (
                  <Popup
                    trigger={
                      <div className="hidden lg:block h-14 cursor-pointer">
                        <img className="h-full" src={ClockAndTabsImage} />
                      </div>
                    }
                    flowing
                    on="hover"
                    position="bottom center"
                  >
                    <div className="bg-white text-sm font-normal">
                      {t("Factoring")}
                    </div>
                  </Popup>
                )}

                {data?.non_standard_publication_conditions === "1" &&
                mode !== "presentation" ? (
                  <SmoothLink
                    className="cursor-pointer leading-5 uppercase bg-primse-red py-5 px-8 rounded-sm transition-all hover:opacity-90 hidden lg:flex lg:justify-center lg:py-5 lg:px-5 items-center"
                    to="agent"
                    smooth={true}
                    offset={-70}
                  >
                    <span className="text-white font-bold whitespace-nowrap">
                      {t("Check publication conditions")}
                    </span>
                  </SmoothLink>
                ) : null}

                <div>
                  <button
                    className={cs(
                      "leading-5 uppercase bg-primse-mid-gray py-5 px-8 rounded-sm transition-all hover:bg-primse-gray",
                      "hidden lg:flex lg:justify-center lg:py-5 lg:px-5 items-center"
                    )}
                    onClick={() => {
                      setProblemModalStatus("investment");
                      setTimeout(() => {
                        actions.modals.openProblemModal(true);
                      }, 10);
                    }}
                  >
                    <span className="text-white font-bold whitespace-nowrap">
                      {t("Ask Primse")}
                    </span>
                  </button>
                </div>
                {visibleOfferInvestment.deskop ? (
                  <OfferInvestment uuid={uuid} visibleMobile={false} />
                ) : null}

                {isAgent() ? (
                  <RequestBriefcaseUser
                    uuid={uuid}
                    developer_name={data?.developer_name}
                    investment_name={data?.name}
                    data={data}
                  />
                ) : null}
                {userRole !== "Deweloper" && (
                  <div>
                    <PopupTrigger
                      uuid={uuid}
                      investmentData={data}
                      type="button"
                      openRequestModal={true}
                    >
                      <Button
                        Icon={CartWhite}
                        className="hidden lg:flex lg:justify-center lg:py-4 lg:px-5 items-center"
                      >
                        {t("Add to basket")}{" "}
                      </Button>
                    </PopupTrigger>
                  </div>
                )}
              </div>
            </header>
          </Container>

          <div>
            <button
              className={cs(
                "leading-5 mt-2 uppercase bg-primse-mid-gray py-5 rounded-sm w-full",
                "md:hidden flex justify-center items-center "
              )}
              onClick={() => {
                setProblemModalStatus("investment");
                setTimeout(() => {
                  actions.modals.openProblemModal(true);
                }, 10);
              }}
            >
              <span className="text-white font-bold whitespace-nowrap">
                {t("Ask Primse")}
              </span>
            </button>
          </div>

          {visibleOfferInvestment.mobile ? (
            <OfferInvestment uuid={uuid} visibleMobile={true} />
          ) : null}
          <ButtonAddToBriefcase
            type="text"
            uuid={data.uuid}
            name={data.name}
            investment={true}
          />

          <Element name="gallery">
            <Gallery
              images={data?.pictures}
              static_map={map}
              data={data}
              showModalMap={showModalMap}
              setShowModalMap={setShowModalMap}
            />
          </Element>
          <div className="bg-primse-yellow sticky top-headerHeigh z-10">
            <Container>
              <ul className="flex -mx-1 lg:-mx-7 h-investmentNavHeight items-center font-medium">
                {INVESTMENT_NAV.map(
                  ({ label, to, offset, mobile, deskop }, index) => {
                    if (
                      (label === "Documents" &&
                        data.documents &&
                        data.documents !== null &&
                        data.documents?.length === 0) ||
                      (label === "Construction schedule" &&
                        data.building_schedule &&
                        data.building_schedule !== null &&
                        data.building_schedule?.length === 0)
                    )
                      return null;
                    return (
                      <li
                        className={cs(
                          "px-3 lg:px-7 cursor-pointer",
                          mobile && deskop === false && "block lg:hidden",
                          mobile === false && deskop && "hidden lg:block"
                        )}
                        key={index}
                      >
                        <SmoothLink
                          className={"text-primse-gray hover:text-white"}
                          to={to}
                          smooth={true}
                          offset={offset}
                        >
                          {label}
                        </SmoothLink>
                      </li>
                    );
                  }
                )}
              </ul>
            </Container>
          </div>
          <Container className="flex flex-col xl:flex-row">
            <div className="xl:w-9/12 xl:pr-7">
              <Element className="hidden lg:block" name="flats">
                {uuid && <FlatList uuid={uuid} />}
              </Element>
              <Element name="description">
                <Description text={data.description} />
              </Element>
              <Element className="lg:hidden" name="flatsMobile">
                {uuid && <FlatListMobile uuid={uuid} />}
              </Element>

              {/* Sekcja details zostaje ukryta do czasu otrzymania danych od klienta */}
              {/* <Element className="hidden lg:inline-block  w-full">
                <Details
                  list={[
                    {
                      label: "Quisque eget eros eu nunc viverra",
                      value:
                        "Mauris scelerisque congue commodo. Suspendisse vestibulum est lorem.",
                    },
                    {
                      label: "Vestibulum eu urna sit amet",
                      value:
                        "Cras bibendum ullamcorper tortor, non dapibus ipsum mollis non.",
                    },
                    {
                      label: "Morbi scelerisque mauris et felis",
                      value:
                        "Nam et vehicula tortor, sit amet auctor augue. Lorem ipsum dolor sit amet, consectetur adipiscing.",
                    },
                  ]}
                />
              </Element> */}

              <Element name="map">
                {map ? (
                  <div
                    className="relative mb-4 cursor-pointer"
                    style={{ height: "400px" }}
                    index={1}
                    onClick={() => setShowModalMap(true)}
                  >
                    <div
                      key={2}
                      className="bg-no-repeat bg-cover bg-center w-full h-full"
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_IMAGES_URL}${map})`,
                      }}
                    />
                    <div
                      style={{ top: "-32px" }}
                      className="absolute  left-0 w-full h-full flex items-center justify-center"
                    >
                      <MapPin />
                    </div>
                    <button
                      className="leading-5 font-bold uppercase bg-primse-green py-4 px-6 rounded-sm transition-all text-white cursor-pointer hover:opacity-80 absolute bottom-3 right-3"
                      onClick={() => setShowModalMap(true)}
                    >
                      <div className="flex items-center">
                        <MapPin height="24" width="24" />
                        <span className="ml-2">{t("See on map")} </span>
                      </div>
                    </button>
                  </div>
                ) : null}
              </Element>
              <Element name="timeline">
                {data?.building_schedule &&
                  data?.building_schedule?.length !== 0 && (
                    <div>
                      <Timeline dataInvestment={data?.building_schedule} />
                    </div>
                  )}
              </Element>
              {mode !== "presentation" ? (
                <Element name="agent">
                  <AgentInformation text={data?.information_for_agent} />
                </Element>
              ) : null}
              {data.documents &&
                data.documents !== null &&
                Array.isArray(data.documents) &&
                data.documents?.length !== 0 && (
                  <Element className="w-full hidden lg:block" name="docs">
                    <Documents files={data.documents} />
                  </Element>
                )}
            </div>
            <aside className="mt-12 xl:w-3/12">
              <div className="mb-7">
                <InfoBoxDeveloper title="Developer" data={developerInfoData} />
              </div>
              <div className="mb-7">
                <Infobox title="More information" data={informationData} />
              </div>
              {data?.investment_status === "On sale" &&
                contactData &&
                mode === "work" && (
                  <div className="mb-7">
                    <Infobox title="Contact details" data={contactData} />
                  </div>
                )}
              {mode === "work" && (
                <div className="mb-7">
                  <Infobox
                    title="Data concerning registrations"
                    data={applicationsData}
                  />
                </div>
              )}
            </aside>
          </Container>

          <NewsSection />
          {data?.lat && data.long && (
            <Modal
              onClose={() => setShowModalMap(false)}
              onOpen={() => setShowModalMap(true)}
              open={showModalMap}
              size="large"
              style={{ height: "80vh" }}
            >
              <div className="absolute -top-10 right-0 xl:-right-10 z-20 cursor-pointer">
                <X
                  color="white"
                  size={32}
                  onClick={() => setShowModalMap(false)}
                />
              </div>
              <MapGoogle
                markersInvestment={[
                  {
                    location: {
                      lat: parseFloat(data.lat),
                      lng: parseFloat(data.long),
                    },
                  },
                ]}
                markersRealestate={[]}
                defaultCenter={{
                  lat: parseFloat(data.lat),
                  lng: parseFloat(data.long),
                }}
                pineComponent={true}
              />
            </Modal>
          )}
        </div>
      )}

      {!isLoading && data && data.investment_status !== "On sale" && (
        <div className=" flex flex-col items-center mt-20">
          <div className="uppercase font-medium text-center pt-4">
            {t("Investment not found")}
          </div>
          <button
            className="leading-5 w-46 m-4 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
            onClick={() => history.push(`/`)}
          >
            <div className="flex">
              {t("Go to the search engine")}
              <ArrowRight className="text-white ml-2 " size={20} />
            </div>
          </button>
        </div>
      )}
    </main>
  );
};

export default Investment;
