import cs from "classnames";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useMemo, useState } from "react";
import { Menu, X } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { Power } from "react-feather";
import Logo from "../Logo";
// import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { Popup } from "semantic-ui-react";
import { Overlay } from "../Overlay";
import Briefcase from "./../Briefcase";
import MyCart from "../MyCart";
import { actions } from "../../store";
import NavHeader from "./components/NavHeader";
import useWindowSize from "../Timeline/useWindowSize";
import DocumentsServices from "../../services/DocumentsServices";
import { toast } from "react-toastify";
import useUserRole from "../../hooks/useUserRole";

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [openInvestmentPopupMenu, setOpenInvestmentPopupMenu] = useState(false);

  // const showLoginModal = useStoreActions((state) => state.auth.showLoginModal);
  const setProblemModalStatus = useStoreActions(
    (state) => state.modals.setProblemModalStatus
  );
  const user = useStoreState((state) => state.auth.user?.username);
  const userRole = useStoreState((state) => state.auth.user?.role);

  const userEmployment = useStoreState((state) => state.auth.user?.employment);

  const isLoggedIn = useStoreState((state) => state.auth.isLoggedIn);
  const size = useWindowSize();

  const { basketModalOpen, basketInfoPopup } = useStoreState(
    (state) => state.baskets
  );
  const { briefcaseModalOpen, briefcaseInfoPopup } = useStoreState(
    (state) => state.briefcase
  );
  const { mode } = useStoreState((state) => state.mode);

  const history = useHistory();
  const { isManager, isAdmin } = useUserRole();

  const { t } = useTranslation();
  const MENU = useMemo(() => {
    //Menu if no use is logged in
    if (!user)
      return [
        {
          link: "https://primse.com/",
          label: t("About Primse"),
          exact: true,
          blank: true,
        },
      ];
    //Menu in presentation mode
    if (mode === "presentation")
      return [
        { link: "/", label: t("Main page"), exact: true },
        {
          list: true,
          mobile: false,
          label: t("Investments"),
          elements: [
            { link: "/investments", label: t("Investments list") },
            { link: "/realestates", label: t("Realestates list") },
          ],
        },

        // { link: "/news", label: t("News") },
        // { link: "/reports", label: t("Reports") },
      ];
    //Menu in work mode
    return [
      {
        list: false,
        link: "/",
        label: t("Main page"),
        exact: true,
        mobile: true,
      },
      {
        list: false,
        link: "/investments",
        label: t("Investments list"),
        mobile: true,
        displayWeb: false,
      },
      {
        list: false,
        link: "/realestates",
        label: t("Realestates list"),
        mobile: true,
        displayWeb: false,
      },
      {
        list: true,
        mobile: false,
        label: t("Investments"),
        elements: [
          { link: "/investments", label: t("Investments list") },
          { link: "/realestates", label: t("Realestates list") },
        ],
      },
      { list: false, link: "/clients", label: t("Clients"), mobile: true },
      {
        list: false,
        link: "/requests",
        label: t("Requests"),
        mobile: true,
      },
      { list: false, link: "/news", label: t("News"), mobile: true },
      // {
      //   list: false,
      //   link: "/report/agency",
      //   label: t("Raport Agencji"),
      //   mobile: true,
      //   displayWeb: false,
      // },
      // {
      //   list: false,
      //   link: "/report/developer",
      //   label: t("Raport Developera"),
      //   mobile: true,
      //   displayWeb: false,
      // },
      // {
      //   list: false,
      //   link: "/questions",
      //   label: t("Questions list"),
      //   mobile: true,
      //   displayWeb: false,
      // },
      // {
      //   list: false,
      //   link: "/transactions",
      //   label: t("Transactions"),
      //   mobile: true,
      //   displayWeb: false,
      // },
      {
        list: true,
        mobile: false,
        redirect: false,
        label: t("More"),
        elements: [
          {
            link: "/report/agency",
            label: t("Agency Report"),
            display: isManager(),
          },
          {
            link: "/report/developer",
            label: t("Developer Report"),
            display: isManager(),
          },
          { link: "/questions", label: t("Questions list"), display: true },
          { link: "/transactions", label: t("Transactions"), display: true },
        ]
          ?.filter((el) => el.display)
          ?.map((el) => ({ link: el.link, label: el.label })),
      },

      // { list: false, link: "/reports", label: t("Reports"), mobile: false },
    ];
  }, [t, user, mode]);

  const MenuDeveloper = useMemo(() => {
    //Menu if no use is logged in
    if (!user) return [];
    //Menu in presentation mode
    return [
      {
        list: false,
        link: "/requests",
        label: t("Requests"),
        mobile: true,
      },
      {
        list: false,
        link: "/investments",
        label: t("Investments list"),
        mobile: true,
      },
      {
        list: false,
        link: "/realestates",
        label: t("Realestates list"),
        mobile: true,
      },
      {
        list: false,
        link: "/questions",
        label: t("Questions list"),
        mobile: true,
      },
      {
        list: false,
        link: "/transactions",
        label: t("Transactions"),
        mobile: true,
      },
      // { list: false, link: "/reports", label: t("Reports"), mobile: true },
    ];
  }, [t, user, mode]);

  const generatePriceList = () => {
    // actions.isLoading.setIsLoading(true);
    toast.success(`${t("Generated price list, download will start soon.")}`);

    DocumentsServices.pdfProvisionsGenerate()
      .then()
      .catch(() => toast.error(`${t("Error, unable to generate offers.")}`));
    // .finally(() => actions.isLoading.setIsLoading(false));
  };

  return (
    <header className="h-headerHeigh border-b-2 sticky top-0 px-12 bg-white shadow-sm z-30">
      {showMobileMenu && <Overlay onClose={() => setShowMobileMenu(false)} />}
      <div className="container mx-auto min-w-full flex items-center h-headerHeigh">
        <div className="-mt-logoOffset mr-7">
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <div
          className={cs(
            "flex flex-1 absolute top-headerHeigh left-0  w-full bg-white flex-col border-t xl:static xl:flex-row xl:border-t-0 transition-all transform  xl:transform -translate-y-0 duration-500",
            { "-translate-y-mobileHeaderHeight": !showMobileMenu }
          )}
        >
          {userRole === "Deweloper" && (
            <NavHeader
              items={MenuDeveloper}
              setShowMobileMenu={setShowMobileMenu}
              openInvestmentPopupMenuMobile={false}
              openInvestmentPopupMenu={openInvestmentPopupMenu}
              setOpenInvestmentPopupMenu={setOpenInvestmentPopupMenu}
            />
          )}
          {userRole !== "Deweloper" && (
            <NavHeader
              items={MENU}
              setShowMobileMenu={setShowMobileMenu}
              openInvestmentPopupMenuMobile={false}
              openInvestmentPopupMenu={openInvestmentPopupMenu}
              setOpenInvestmentPopupMenu={setOpenInvestmentPopupMenu}
            />
          )}

          <ul
            className="w-full items-center xl:justify-end xl:flex-row xl:mt-0 flex
            justify-center
            flex-col
            font-bold
            whitespace-pre
          "
          >
            {isLoggedIn && (
              <>
                {userRole !== "Deweloper" && (
                  <>
                    {size.width > 1279 && (
                      <>
                        <Popup
                          trigger={
                            <li
                              className="hidden xl:block"
                              onClick={() => setShowMobileMenu(false)}
                            >
                              <Briefcase />
                            </li>
                          }
                          mouseEnterDelay={800}
                          open={briefcaseModalOpen ? false : briefcaseInfoPopup}
                          onOpen={() => {
                            if (!briefcaseModalOpen)
                              actions.briefcase.setBriefcaseInfoPopup(true);
                          }}
                          onClose={() =>
                            actions.briefcase.setBriefcaseInfoPopup(false)
                          }
                          on="hover"
                          position="bottom center"
                        >
                          <div className="bg-white text-sm font-normal text-center">
                            {t("In the briefcase, you store...")}
                          </div>
                        </Popup>
                        <Popup
                          trigger={
                            <li
                              className="hidden xl:block"
                              onClick={() => setShowMobileMenu(false)}
                            >
                              <MyCart />
                            </li>
                          }
                          open={basketModalOpen ? false : basketInfoPopup}
                          onOpen={() => {
                            if (!basketModalOpen)
                              actions.baskets.setBasketInfoPopup(true);
                          }}
                          onClose={() =>
                            actions.baskets.setBasketInfoPopup(false)
                          }
                          mouseEnterDelay={800}
                          on="hover"
                          position="bottom center"
                        >
                          <div className="bg-white text-sm font-normal text-center">
                            {t("In baskets, you create collections...")}
                          </div>
                        </Popup>
                      </>
                    )}
                    {size.width <= 1279 && (
                      <>
                        <li onClick={() => setShowMobileMenu(false)}>
                          <Briefcase />
                        </li>
                        <li onClick={() => setShowMobileMenu(false)}>
                          <MyCart />
                        </li>
                      </>
                    )}
                  </>
                )}
                <div className="ml-4">
                  <Popup
                    trigger={
                      <div
                        className="hidden xl:flex relative hover:text-primse-green cursor-pointer items-center"
                        // onClick={() => AuthService.logOut(null)}
                      >
                        <div className={cs("mr-2.5 text-primse-green")}>
                          <Power width={16} />
                        </div>
                        {user}
                      </div>
                    }
                    flowing
                    hoverable
                  >
                    <div className={cs("bg-white  text-sm font-normal")}>
                      {/* <div className="border-b">
                      <div className="p-3">
                        <strong>{t("Select language")}</strong>
                      </div>
                      <div className="p-3 py-4 flex">
                        <div
                          className="pr-4 cursor-pointer hover:text-primse-green"
                          onClick={() => i18n.changeLanguage("hu")}
                        >
                          <div
                            className={cs("flex items-center", {
                              "font-bold": i18n.language === "hu",
                            })}
                          >
                            <Flag name="hu" /> {t("Hungarian")}
                          </div>
                        </div>
                        <div
                          className="pr-4 cursor-pointer hover:text-primse-green"
                          onClick={() => i18n.changeLanguage("en")}
                        >
                          <div
                            className={cs("flex items-center", {
                              "font-bold": i18n.language === "en",
                            })}
                          >
                            <Flag name="uk" /> {t("English")}
                          </div>
                        </div>
                        <div
                          className="cursor-pointer hover:text-primse-green"
                          onClick={() => i18n.changeLanguage("pl")}
                        >
                          <div
                            className={cs("flex items-center", {
                              "font-bold": i18n.language === "pl",
                            })}
                          >
                            <Flag name="pl" /> {t("Polish")}
                          </div>
                        </div>
                      </div>
                    </div> */}
                      {userRole !== "Deweloper" && (
                        <div className="border-b hidden lg:block">
                          <div className="p-3">
                            <strong>{t("Work mode")}</strong>
                          </div>
                          <div className="p-3 py-4 flex">
                            <div
                              className={cs(
                                "pr-4 cursor-pointer hover:text-primse-green",
                                { "font-bold": mode === "work" }
                              )}
                              onClick={() => actions.mode.setMode("work")}
                            >
                              {t("Work")}
                            </div>
                            <div
                              className={cs(
                                "pr-4 cursor-pointer hover:text-primse-green",
                                { "font-bold": mode === "presentation" }
                              )}
                              onClick={() =>
                                actions.mode.setMode("presentation")
                              }
                            >
                              {t("Presentation")}
                            </div>
                          </div>
                        </div>
                      )}
                      {userEmployment &&
                      userEmployment.supervisor_last_name &&
                      mode === "work" ? (
                        <div className="p-3 py-4 border-b">
                          <p>
                            <strong className="">{t("Guardian")}:</strong>
                          </p>
                          {userEmployment.supervisor_last_name &&
                            userEmployment.supervisor_name && (
                              <p>
                                {userEmployment.supervisor_last_name}{" "}
                                {userEmployment.supervisor_name}
                              </p>
                            )}
                          {userEmployment.supervisor_phone && (
                            <p>
                              {t("phone")}: {userEmployment.supervisor_phone}
                            </p>
                          )}
                          {userEmployment.supervisor_mail && (
                            <a
                              className="text-black hover:text-primse-green ursor-pointer"
                              href={`mailto:${userEmployment.supervisor_mail}`}
                            >
                              {userEmployment.supervisor_mail}
                            </a>
                          )}
                        </div>
                      ) : null}
                      {isAdmin() && (
                        <div
                          onClick={() => generatePriceList()}
                          className="p-3 py-4 border-b  cursor-pointer  hover:text-primse-green"
                        >
                          <strong className="cursor-pointer  ">
                            {t("Generate an investment price list")}
                          </strong>
                        </div>
                      )}

                      <div
                        onClick={() => {
                          setProblemModalStatus("");
                          setTimeout(() => {
                            actions.modals.openProblemModal(true);
                          }, 0);
                        }}
                        className="p-3 py-4 border-b  cursor-pointer  hover:text-primse-green"
                      >
                        <strong className="cursor-pointer  ">
                          {t("Ask Primse")}
                        </strong>
                      </div>
                      <div
                        onClick={() => actions.modals.openEditUserModal(true)}
                        className="p-3 py-4 border-b  cursor-pointer  hover:text-primse-green"
                      >
                        <strong className="cursor-pointer ">
                          {t("My profile")}
                        </strong>
                      </div>
                      <div
                        className="p-3 py-4 cursor-pointer hover:text-primse-green"
                        onClick={() => {
                          history.push("/logout");
                        }}
                      >
                        <strong>{t("Logout")}</strong>
                      </div>
                    </div>
                  </Popup>
                  <div
                    onClick={() => {
                      setProblemModalStatus("");
                      setTimeout(() => {
                        actions.modals.openProblemModal(true);
                      }, 0);
                    }}
                    className="xl:hidden p-3 py-4 border-t  cursor-pointer  hover:text-primse-green"
                  >
                    <p className="font-medium">{t("Ask Primse")}</p>
                  </div>
                  <div
                    className="xl:hidden p-3 py-4 cursor-pointer hover:text-primse-green"
                    onClick={() => {
                      history.push("/logout");
                    }}
                  >
                    <strong>{t("Logout")}</strong>
                  </div>

                  {userEmployment &&
                  userEmployment.supervisor_last_name &&
                  mode === "work" ? (
                    <div className="flex flex-row">
                      <strong className="">{t("Guardian")}:</strong>
                      <Popup
                        trigger={
                          <div className="hidden xl:flex px-1 relative hover:text-primse-green cursor-pointer items-center">
                            {userEmployment.supervisor_last_name &&
                              userEmployment.supervisor_name && (
                                <p>
                                  {userEmployment.supervisor_last_name}{" "}
                                  {userEmployment.supervisor_name}
                                </p>
                              )}
                          </div>
                        }
                        flowing
                        on="click"
                      >
                        <div>
                          <div className="flex flex-col mb-2">
                            <strong>{t("Agency supervisor")}</strong>
                            {userEmployment.supervisor_last_name &&
                              userEmployment.supervisor_name && (
                                <p>
                                  {userEmployment.supervisor_last_name}{" "}
                                  {userEmployment.supervisor_name}
                                </p>
                              )}
                          </div>
                          {userEmployment.supervisor_phone && (
                            <div className="flex flex-col mb-2">
                              <strong>{t("phone")}</strong>
                              <p>{userEmployment.supervisor_phone}</p>
                            </div>
                          )}
                          {userEmployment.supervisor_mail && (
                            <div className="flex flex-col">
                              <strong>{t("E-mail")}</strong>
                              <a
                                className="text-black hover:text-primse-green cursor-pointer"
                                href={`mailto:${userEmployment.supervisor_mail}`}
                              >
                                {userEmployment.supervisor_mail}
                              </a>
                            </div>
                          )}
                        </div>
                      </Popup>
                    </div>
                  ) : null}
                </div>
              </>
            )}
            {!isLoggedIn && (
              <>
                {/* <div className="flex flex-col items-center">
                  <li
                    className="px-2 py-4 cursor-pointer"
                    onClick={() => {
                      showLoginModal;
                      setShowMobileMenu(false);
                    }}
                  >
                    {t("Log in")}
                  </li>
                  <div className="p-3 py-4 pb-8 flex">
                    <div
                      className="pr-4 cursor-pointer hover:text-primse-green"
                      onClick={() => i18n.changeLanguage("en")}
                    >
                      <div
                        className={cs("flex items-center", {
                          "font-bold": i18n.language === "en",
                        })}
                      >
                        <Flag name="uk" /> {t("English")}
                      </div>
                    </div>
                    <div
                      className="cursor-pointer hover:text-primse-green"
                      onClick={() => i18n.changeLanguage("pl")}
                    >
                      <div
                        className={cs("flex items-center", {
                          "font-bold": i18n.language === "pl",
                        })}
                      >
                        <Flag name="pl" /> {t("Polish")}
                      </div>
                    </div>
                  </div>
                </div> */}
                <li className=" xl:hidden px-2 py-4 cursor-pointer">
                  <Link
                    onClick={() => setShowMobileMenu(false)}
                    to={{ pathname: "https://primse.com/" }}
                    className="relative text-black hover:text-black"
                    target={"_blank"}
                  >
                    {t("About Primse")}
                  </Link>
                </li>
                {/* <Popup
                  trigger={
                    <li
                      className="px-2 py-4 cursor-pointer"
                      onClick={showLoginModal}
                    >
                      {t("Log in")}
                    </li>
                  }
                  flowing
                  hoverable
                >
                  <div className={cs("bg-white  text-sm font-normal")}> */}
                {/*<div className="">*/}
                {/*  <div className="p-3">*/}
                {/*    <strong>{t("Select language")}</strong>*/}
                {/*    <div className="p-3 py-4 flex">*/}
                {/*      <div*/}
                {/*        className="pr-4 cursor-pointer hover:text-primse-green"*/}
                {/*        onClick={() => i18n.changeLanguage("en")}*/}
                {/*      >*/}
                {/*        <div*/}
                {/*          className={cs("flex items-center", {*/}
                {/*            "font-bold": i18n.language === "en",*/}
                {/*          })}*/}
                {/*        >*/}
                {/*          <Flag name="uk" /> {t("English")}*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*      <div*/}
                {/*        className="cursor-pointer hover:text-primse-green"*/}
                {/*        onClick={() => i18n.changeLanguage("pl")}*/}
                {/*      >*/}
                {/*        <div*/}
                {/*          className={cs("flex items-center", {*/}
                {/*            "font-bold": i18n.language === "pl",*/}
                {/*          })}*/}
                {/*        >*/}
                {/*          <Flag name="pl" /> {t("Polish")}*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/* </div> */}
                {/* </Popup> */}
              </>
            )}
          </ul>
          {/* <div className="flex ml-auto">
          <div className="px-7">Moje koszyki</div>
          <div className="pl-7">Andrzej Test</div>
        </div> */}
        </div>
        {userEmployment &&
        userEmployment.supervisor_last_name &&
        mode === "work" ? (
          <div className="xl:hidden">
            <strong>{user}</strong>
            <div className="flex flex-row">
              <strong className="">{t("Guardian")}: </strong>
              <Popup
                trigger={
                  <div className="cursor-pointer px-1 items-center hover:text-primse-green">
                    {userEmployment.supervisor_last_name &&
                      userEmployment.supervisor_name && (
                        <p>
                          {userEmployment.supervisor_last_name}{" "}
                          {userEmployment.supervisor_name}
                        </p>
                      )}
                  </div>
                }
                flowing
                on="click"
              >
                <div>
                  <div className="flex flex-col mb-2">
                    <strong>{t("Agency supervisor")}</strong>
                    {userEmployment.supervisor_last_name &&
                      userEmployment.supervisor_name && (
                        <p>
                          {userEmployment.supervisor_last_name}{" "}
                          {userEmployment.supervisor_name}
                        </p>
                      )}
                  </div>
                  {userEmployment.supervisor_phone && (
                    <div className="flex flex-col mb-2">
                      <strong>{t("Phone")}</strong>
                      <p>{userEmployment.supervisor_phone}</p>
                    </div>
                  )}
                  {userEmployment.supervisor_mail && (
                    <div className="flex flex-col">
                      <strong>{t("E-mail")}</strong>
                      <a
                        className="text-black hover:text-primse-green ursor-pointer"
                        href={`mailto:${userEmployment.supervisor_mail}`}
                      >
                        {userEmployment.supervisor_mail}
                      </a>
                    </div>
                  )}
                </div>
              </Popup>
            </div>
          </div>
        ) : null}
        <div
          className="xl:hidden flex-1 flex justify-end"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          {showMobileMenu && <X />}
          {!showMobileMenu && <Menu />}
        </div>
      </div>
    </header>
  );
};

export default Header;
