import cs from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { ChevronDown, ChevronUp, X } from "react-feather";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import Loader from "../../components/Loader";
import FlatHover from "../../components/MapGoogle/components/FlatHover";
import InvestmentsService from "../../services/InvestmentsService";

import AreaDropdown from "./components/Flat/SearchComponents/AreaDropdown";
import DistrictDropdown from "./components/Flat/SearchComponents/DistrictDropdown";
import PriceDropdown from "./components/Flat/SearchComponents/PriceDropdown";
import RoomsDropdown from "./components/Flat/SearchComponents/RoomsDropdown";
import TypeDropdown from "./components/Flat/SearchComponents/TypeDropdown";
import {
  DefaultValueInput,
  DistrictValueInput,
} from "./components/Flat/SearchComponents/ValueInputs";
import Recomendation from "./components/Recomendation";
import styles from "./Search.module.css";
import LocationsServices from "../../services/LocationsServies";

import {
  convertValuesToURL,
  DEFAULTVALUES,
  formatInvestmentData,
  parseURLtoFormValues,
  getParamsToApi,
} from "./searchUtils";
import { useStoreActions, useStoreState } from "easy-peasy";
import NewsServices from "../../services/NewsServices";
import FlatInvestmentMobile from "./components/FlatInvestmentMobile";
import AdvancedDropdown from "./components/Flat/SearchComponents/AdvancedDropdown";
import { Overlay } from "../../components/Overlay";
import { BiSearchAlt2 } from "react-icons/all";
import { Link } from "react-router-dom";

import {
  getAdvancedPlaceholder,
  getAreaPlaceholder,
  getPricePlaceholder,
  getRoomsPlaceholder,
  getTypePlaceholder,
} from "./getPlaceholders";
import MapGoogle from "../../components/MapGoogle";
import RealestatesServices from "../../services/RealestatesServices";
import { parseThousands } from "../../utils/numbers";
import MapInvestment from "./components/MapInvestment";
import MapRealestateView from "./components/MapRealestateView";
import MapInvestmentsCluster from "./components/MapInvestmentsCluster";

import buildingImg from "../../components/MapGoogle/assets/building@2x.png";
import MapRealestatesCluster from "./components/MapRealestatesCluster";

const Search = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [markersInvestment, setMarkersInvestment] = useState([]);
  const [markersRealestate, setMarkersRealestate] = useState([]);

  const [selectedMarkets, setSelectedMarkets] = useState([
    { name: "Pierwotny", checked: true },
    {
      name: "Wtórny",
      checked: false,
    },
  ]);

  const { mode } = useStoreState((state) => state.mode);
  const [resetQuery, setResetQuery] = useState(false);
  const { handleSubmit, watch, control, setValue } = useForm({
    defaultValues: parseURLtoFormValues(location.search),
  });
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const [activeLoader, setActiveLoader] = useState(true);
  const setSearchFilters = useStoreActions(
    (state) => state.filters.setSearchFilters
  );

  const searchFilters = useStoreState((state) => state.filters.searchFilters);
  const completion_quarters = useStoreState(
    (state) => state.dictionaries.dictionariesList.completion_quarters
  );

  // const showSecondaryMarket = useStoreState(
  //   (state) => state.auth.user?.employment?.secondary_market
  // );

  const { isLoading: appIsLoading } = useStoreState((state) => state.isLoading);

  useEffect(() => {
    if (searchFilters) {
      history.replace(`/?${convertValuesToURL(searchFilters)}`);
      for (const [key, value] of Object.entries(searchFilters)) {
        setValue(key, value);
      }
    }
  }, [searchFilters]);

  const values = watch();

  const { data: news } = useQuery("news", NewsServices.getNews);

  const { data: locationsData } = useQuery(
    [values.type],
    LocationsServices.getLocations(values.type)
  );

  const searchNewData = async (emptyParams = false) => {
    setActiveLoader(true);
    setSearchFilters(parseURLtoFormValues(emptyParams ? "" : location.search));
    if (showMobileMenu) {
      setShowMobileMenu(false);
    }

    if (selectedMarkets[0]?.checked) {
      await handlePrimaryMarket(parseURLtoFormValues(location.search));
    } else {
      setMarkersInvestment([]);
    }

    if (selectedMarkets[1]?.checked) {
      await handleSecondaryMarket(parseURLtoFormValues(location.search));
    } else {
      setMarkersRealestate([]);
    }

    setTimeout(() => {
      setActiveLoader(false);
    }, 300);
  };

  useEffect(() => {
    if (
      location.search ||
      location?.search?.slice(1) === convertValuesToURL(searchFilters)
    ) {
      searchNewData();
    }
  }, [location.search, selectedMarkets]);

  const handleSetQuery = (value) => {
    setResetQuery(value);
  };
  const handleSortAsc = (arr) => {
    return arr.sort((a, b) => (a.value > b.value ? 1 : -1));
  };

  const cities = useMemo(() => {
    if (!locationsData) return [];
    const result = locationsData.map(
      ({
        city_name,
        voivodeship_name,
        quarter_name,
        type,
        voivodeship_id,
        city_id,
        quarter_id,
      }) => {
        switch (type) {
          case "voivodeship":
            return {
              value: voivodeship_name.toLowerCase(),
              name: voivodeship_name,
              description: `Całe województwo`,
              id: `v:${voivodeship_id}`,
              type: type,
            };
          case "city":
            return {
              value: city_name.toLowerCase(),
              name: city_name,
              description: `${voivodeship_name}`,
              id: `c:${city_id}`,
              type: type,
            };
          case "quarter":
            return {
              value: quarter_name.toLowerCase(),
              name: quarter_name,
              description: city_name,
              id: `q:${quarter_id}`,
              type: type,
            };
        }
      }
    );
    const voivodeships = handleSortAsc(
      result?.filter((el) => el.type === "voivodeship")
    );
    const citys = handleSortAsc(result?.filter((el) => el.type === "city"));
    const quarters = handleSortAsc(
      result?.filter((el) => el.type === "quarter")
    );

    return [...citys, ...quarters, ...voivodeships];
  }, [locationsData]);

  const filterInvestments = (data) => {
    if (!data) return null;
    setMarkersInvestment(
      data
        .filter(({ lat, long }) => lat && long)
        .map((investment) => formatInvestmentData(investment))
    );
  };

  const onSubmit = (values) => {
    if (convertValuesToURL(values) === "") {
      history.push(`/`);
      return searchNewData(true);
    }

    history.push(`/?${convertValuesToURL(values)}`);

    return values;
  };

  const handleReset = () => {
    if (resetQuery === false) setResetQuery(!resetQuery);
    Object.entries(DEFAULTVALUES)?.forEach(([key, value]) => {
      setValue(key, value);
    });
    setSearchFilters(DEFAULTVALUES);
    history.push(`/?${convertValuesToURL(DEFAULTVALUES)}`);
  };

  const handlePrimaryMarket = async (values) => {
    await InvestmentsService.getInvestments(getParamsToApi(values)).then(
      (res) => {
        setTimeout(() => {
          filterInvestments(res);
        }, 300);
      }
    );
  };

  const handleSecondaryMarket = async (values) => {
    await RealestatesServices.getRealestates(getParamsToApi(values))().then(
      (res) => {
        setMarkersRealestate([]);
        if (res) {
          setMarkersRealestate(
            res.payload
              ?.filter(
                ({ sm_latitude, sm_longitude }) => sm_latitude && sm_longitude
              )
              .map((el) => ({
                ...el,
                location: {
                  lat: el?.sm_latitude,
                  lng: el?.sm_longitude,
                },
                pictures: el?.files ? JSON.parse(el?.files) : null,
              }))
          );
        }
      }
    );
  };

  const sortedDataDownDown = (sortBy, news) => {
    const arr = [...news];
    arr.sort((a, b) => (a[sortBy] > b[sortBy] ? -1 : 1));

    return arr;
  };

  return (
    <main className={cs("h-full flex flex-col lg:px-8", styles.mainContainer)}>
      {activeLoader && <Loader />}
      {appIsLoading && <Loader />}
      {showMobileMenu && <Overlay onClose={() => setShowMobileMenu(false)} />}
      <header className="sticky top-20 py-4 lg:pt-4 lg:pb-0 lg:top-20  z-20 bg-white w-screen lg:w-full">
        <form
          className={cs(
            "flex flex-col lg:flex-row items-center justify-center absolute w-screen lg:w-full bg-white lg:static lg:justify-start lg:border-t-0 lg:py-0 transition-all transform  lg:transform -translate-y-0 duration-500",
            { "-translate-y-mobileClientSearchHeight": !showMobileMenu }
          )}
          onSubmit={handleSubmit(onSubmit, () => {})}
        >
          <div className="w-64 mx-2 my-2 lg:my-2">
            <DefaultValueInput
              placeholder={getTypePlaceholder(values)}
              control={control}
              Modal={TypeDropdown}
            />
          </div>

          <div className="w-64 lg:w-60 mx-2 my-2 lg:my-2">
            <DistrictValueInput
              control={control}
              cities={cities}
              Modal={DistrictDropdown}
              resetQuery={resetQuery}
              handleSetQuery={handleSetQuery}
              watch={watch}
            />
          </div>
          <div className="w-64 lg:w-60 mx-2 my-2 lg:my-2">
            <DefaultValueInput
              placeholder={getAreaPlaceholder(values)}
              control={control}
              Modal={AreaDropdown}
            />
          </div>
          <div className="w-64 lg:w-60 mx-2 my-2 lg:my-2">
            <DefaultValueInput
              placeholder={getRoomsPlaceholder(values)}
              control={control}
              Modal={RoomsDropdown}
            />
          </div>
          <div className="w-64 mx-2 my-2 lg:my-2">
            <DefaultValueInput
              placeholder={getPricePlaceholder(values)}
              control={control}
              Modal={PriceDropdown}
            />
          </div>
          <div className="mx-2 hidden lg:block">
            <DefaultValueInput
              placeholder={getAdvancedPlaceholder(values)}
              control={control}
              Modal={AdvancedDropdown}
              setValue={setValue}
              completion_quarters={completion_quarters}
            />
          </div>
          <div className="mx-2">
            <button
              className="leading-5 flex items-center m-auto py-3 px-2 xl:px-4 text-sm"
              type="button"
              onClick={handleReset}
            >
              <X size={14} className="mr-2" />
              {t("Clear")}
            </button>
          </div>
          <div className="mx-2 my-2 lg:my-2">
            <button
              className="leading-5 font-bold text-black uppercase bg-primse-yellow py-3 px-6 xl:px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
              type="submit"
            >
              {t("Search")}
            </button>
          </div>
          <div
            className="lg:hidden flex justify-start pt-6 pb-2 mbx-2 sticky top-20 z-10 bg-white"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <div className="text-primse-green flex items-center">
              {showMobileMenu && <ChevronUp size={24} />}
              {!showMobileMenu && <ChevronDown size={24} />}
              <BiSearchAlt2 size={24} />
            </div>
          </div>
        </form>
      </header>

      <div className="mt-4 flex min-h-full max-h-full flex-col xl:flex-row">
        <div
          className={cs(
            "w-full mb-3 xl:mb-0 xl:pr-3 hidden lg:block",
            styles.mapContainer
          )}
        >
          <MapGoogle
            displayElements={{
              selectMarket: true,
              basketPanel: true,
            }}
            selectedMarkets={selectedMarkets}
            setSelectedMarkets={setSelectedMarkets}
            markersInvestment={markersInvestment}
            markersRealestate={markersRealestate}
            defaultCenter={{ lat: 51.9283628, lng: 19.6355535 }}
            TooltipEl={FlatHover}
            MarkerContent={({ price_from, price_to }) => {
              return (
                <>
                  <img src={buildingImg} width={20} height={20} alt="icon" />
                  <div className="mt-2 text-center">
                    <div>{parseThousands(price_from)}</div>
                    <div>-</div>
                    <div>{parseThousands(price_to)}</div>
                  </div>
                </>
              );
            }}
            ActiveMarkerNew={(
              data,
              variant,
              onClosedMarker,
              setActiveScrollMap
            ) => (
              <div>
                {variant === "investment" ? (
                  <MapInvestment
                    data={data}
                    onClosedMarker={onClosedMarker}
                    setActiveScrollMap={setActiveScrollMap}
                  />
                ) : (
                  <MapRealestateView
                    data={data}
                    onClosedMarker={onClosedMarker}
                    setActiveScrollMap={setActiveScrollMap}
                  />
                )}
              </div>
            )}
            ActiveMarker={(
              {
                uuid,
                name,
                price_from,
                price_to,
                area_from,
                area_to,
                rooms_from,
                rooms_to,
                number_of_offers,
                city,
                pictures,
                address_street,
                developer_name,
                completion_date,
                promoting_agency_offer,
                variant = "investment",
              },
              onClosedMarker,
              setActiveScrollMap
            ) => (
              <div>
                {variant === "investment" ? (
                  <MapInvestment
                    uuid={uuid}
                    name={name}
                    price_from={price_from}
                    price_to={price_to}
                    area_from={area_from}
                    area_to={area_to}
                    rooms_from={rooms_from}
                    rooms_to={rooms_to}
                    number_of_offers={number_of_offers}
                    city={city}
                    pictures={pictures}
                    address_street={address_street}
                    onClosedMarker={onClosedMarker}
                    setActiveScrollMap={setActiveScrollMap}
                    developer_name={developer_name}
                    completion_date={completion_date}
                    promoting_agency_offer={promoting_agency_offer}
                  />
                ) : (
                  <MapRealestateView
                    uuid={uuid}
                    name={name}
                    pictures={pictures}
                    onClosedMarker={onClosedMarker}
                    setActiveScrollMap={setActiveScrollMap}
                    developer_name={developer_name}
                  />
                )}
              </div>
            )}
            ActiveCluster={(
              investments,
              onClosedMarker,
              setActiveScrollMap,
              isInvestments = true
            ) => (
              <>
                {isInvestments ? (
                  <MapInvestmentsCluster
                    investments={investments}
                    onClosedMarker={onClosedMarker}
                    setActiveScrollMap={setActiveScrollMap}
                  />
                ) : (
                  <MapRealestatesCluster
                    realestates={investments}
                    onClosedMarker={onClosedMarker}
                    setActiveScrollMap={setActiveScrollMap}
                  />
                )}
              </>
            )}
          />
        </div>
        <div className="block lg:hidden  ">
          <div
            className={cs(
              "xl:px-3 -mb-5 w-full flex flex-wrap xl:block overflow-auto h-full"
            )}
          >
            {markersInvestment.map((investment) => (
              <div
                key={investment.uuid}
                className="w-full sm:w-1/2 sm:p-4 md:w-1/3 md:p-3 "
              >
                <FlatInvestmentMobile
                  key={investment.uuid}
                  investmentData={investment}
                />
              </div>
            ))}
          </div>
        </div>

        <div
          //Style height attribute prevents from display problem on Safari
          style={{ height: "80vh" }}
          className={cs("xl:mt-0 mt-4 flex flex-col", {
            hidden: mode === "presentation",
          })}
        >
          <div
            className={cs(
              "xl:px-3 -mb-5 w-full flex xl:block overflow-auto h-full flex-col"
            )}
          >
            {news && news?.length > 0 && (
              <div className="  pb-6  mt-6 xl:mt-0">
                <Link className="text-primse-gray" to={`news`}>
                  <strong className="cursor-pointer hover:text-primse-green">
                    {t("News")}:{" "}
                    <span className="text-primse-green">{news?.length}</span>
                  </strong>
                </Link>
              </div>
            )}
            <div className="flex xl:flex-col flex-wrap">
              {news &&
                news?.length > 0 &&
                sortedDataDownDown("active_date", news)
                  ?.slice(0, 10)
                  .map(({ uuid, banner_text, files, subject }) => (
                    <div
                      key={uuid}
                      className="w-full p-2 sm:w-1/2 sm:p-4 md:w-1/3 md:p-3 lg:p-0"
                      // className="w-1/2 md:w-1/2 sm:p-4 lg:p-0 xl:w-full"
                    >
                      <Recomendation
                        uuid={uuid}
                        banner_text={banner_text}
                        files={files}
                        subject={subject}
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Search;
