import { useQuery } from "react-query";
import RequestsServices from "../../services/RequestsServices";
import Loader from "../../components/Loader";
import RequestsView from "./components/RequestsView";
import { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useTranslation } from "react-i18next";

const Requests = () => {
  const [refresh, setRefresh] = useState(false);
  const [applications, setApplications] = useState(null);
  const { data, isLoading } = useQuery(
    "requests",
    RequestsServices.getRequests
  );

  const { t } = useTranslation();
  const [sortTypeName, setSortTypeName] = useState("date_down");
  const [activePage, setActivePage] = useState(1);
  const [searchedClientName, setSearchedClientName] = useState("");
  const [searchedId, setSearchedId] = useState("");
  const [searchedSendAfter, setSearchedSendAfter] = useState("");
  const [searchedSendBefore, setSearchedSendBefore] = useState("");
  const [searchedInvestment, setSearchedInvestment] = useState("");
  const [searchedDeveloper, setSearchedDeveloper] = useState("");
  const [searchedStatus, setSearchedStatus] = useState("");
  const [searchedCreatedBefore, setSearchedCreatedBefore] = useState("");
  const [searchedStatusChangeBefore, setSearchedStatusChangeBefore] = useState(
    ""
  );
  const [searchedUser, setSearchedUser] = useState("");

  const setRequestFiltersSugestions = useStoreActions(
    (state) => state.filters.setRequestFiltersSugestions
  );

  const requestFilter = useStoreState((state) => state.filters.requestFilter);

  useEffect(() => {
    if (requestFilter?.searchedClientName)
      setSearchedClientName(requestFilter.searchedClientName);
    if (requestFilter?.searchedId) setSearchedId(requestFilter.searchedId);
    if (requestFilter?.searchedSendAfter)
      setSearchedSendAfter(requestFilter.searchedSendAfter);
    if (requestFilter?.searchedSendBefore)
      setSearchedSendBefore(requestFilter.searchedSendBefore);
    if (requestFilter?.searchedInvestment)
      setSearchedInvestment(requestFilter.searchedInvestment);
    if (requestFilter?.searchedDeveloper)
      setSearchedDeveloper(requestFilter.searchedDeveloper);
    if (requestFilter?.searchedStatus)
      setSearchedStatus(requestFilter.searchedStatus);
    if (requestFilter?.searchedCreatedBefore)
      setSearchedCreatedBefore(requestFilter.searchedCreatedBefore);
    if (requestFilter?.searchedStatusChangeBefore)
      setSearchedStatusChangeBefore(requestFilter.searchedStatusChangeBefore);
    if (requestFilter?.searchedUser) {
      setSearchedUser(requestFilter.searchedUser);
    }
  }, []);

  const investmentSuggestions = (data) => {
    const Arr = [];
    data?.forEach((el) => {
      Arr.push({
        value: el.investment_name,
        text: el.investment_name,
      });
    });

    return Array.from(
      new Set(Arr.map((item) => JSON.stringify(item)))
    ).map((string) => JSON.parse(string));
  };

  const developerSuggestions = (data) => {
    const Arr = [];
    data?.forEach((el) => {
      Arr.push({
        value: el.developer_name,
        text: el.developer_name,
      });
    });

    return Array.from(
      new Set(Arr.map((item) => JSON.stringify(item)))
    ).map((string) => JSON.parse(string));
  };

  const statusSuggestions = (data) => {
    const Arr = [];
    data?.forEach((el) => {
      Arr.push({
        value: el.status,
        text: `${t(`${el.status}`)}`,
      });
    });
    Arr.push({
      value: "All",
      text: `${t("All")}`,
    });

    return Array.from(
      new Set(Arr.map((item) => JSON.stringify(item)))
    ).map((string) => JSON.parse(string));
  };

  useEffect(() => {
    if (applications?.length > 0) {
      setRequestFiltersSugestions({
        status: statusSuggestions(applications),
        developers: developerSuggestions(applications),
        investments: investmentSuggestions(applications),
      });
    }
  }, [applications]);

  useEffect(() => {
    if (data) {
      setApplications(data);
    }
  }, [data]);

  useEffect(() => {
    if (refresh === true) {
      RequestsServices.getRequests()
        .then((res) => setApplications(res))
        .finally(() => setRefresh(false));
    }
  }, [refresh]);

  const handleRefresh = (value) => {
    setRefresh(value);
  };

  return (
    <main className="py-0">
      <div>
        {isLoading && <Loader />}
        {refresh && <Loader />}
        {applications && (
          <RequestsView
            applications={applications}
            handleRefresh={handleRefresh}
            sortTypeName={sortTypeName}
            setSortTypeName={setSortTypeName}
            activePage={activePage}
            setActivePage={setActivePage}
            searchedClientName={searchedClientName}
            setSearchedClientName={setSearchedClientName}
            searchedId={searchedId}
            setSearchedId={setSearchedId}
            searchedSendAfter={searchedSendAfter}
            setSearchedSendAfter={setSearchedSendAfter}
            searchedSendBefore={searchedSendBefore}
            setSearchedSendBefore={setSearchedSendBefore}
            searchedInvestment={searchedInvestment}
            setSearchedInvestment={setSearchedInvestment}
            searchedDeveloper={searchedDeveloper}
            setSearchedDeveloper={setSearchedDeveloper}
            searchedStatus={searchedStatus}
            setSearchedStatus={setSearchedStatus}
            searchedCreatedBefore={searchedCreatedBefore}
            setSearchedCreatedBefore={setSearchedCreatedBefore}
            searchedStatusChangeBefore={searchedStatusChangeBefore}
            setSearchedStatusChangeBefore={setSearchedStatusChangeBefore}
            searchedUser={searchedUser}
            setSearchedUser={setSearchedUser}
          />
        )}
      </div>
    </main>
  );
};

// ApplicationsView.propTypes = {};

// ApplicationsView.defaultProps = {};

export default Requests;
