import Container from "../../../../components/Container";
import PropTypes from "prop-types";
import { Dropdown, Input, Pagination } from "semantic-ui-react";
import { ChevronDown, ChevronUp, X } from "react-feather";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import RequestsItemMobile from "../RequestsItemMobile";
import NewsSection from "../../../../components/NewsSection";
import RequestsSingleItem from "../RequestsSingleItem";
import { Overlay } from "../../../../components/Overlay";
import cs from "classnames";
import { BiSearchAlt2 } from "react-icons/all";
import { useStoreActions, useStoreState } from "easy-peasy";
import { clearDuplicatesFromArray } from "../../../../utils/searchUtils";

const RequestsView = ({
  applications,
  handleRefresh,
  sortTypeName,
  setSortTypeName,
  activePage,
  setActivePage,
  searchedClientName,
  setSearchedClientName,
  searchedId,
  setSearchedId,
  searchedSendAfter,
  setSearchedSendAfter,
  searchedSendBefore,
  setSearchedSendBefore,
  searchedInvestment,
  setSearchedInvestment,
  searchedDeveloper,
  setSearchedDeveloper,
  searchedStatus,
  setSearchedStatus,
  searchedCreatedBefore,
  setSearchedCreatedBefore,
  searchedStatusChangeBefore,
  setSearchedStatusChangeBefore,
  searchedUser,
  setSearchedUser,
}) => {
  const [pagination, setPagination] = useState({
    data: [],
    offset: 0,
    numberPerPage: 20,
    pageCount: 1,
    currentData: [],
  });
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { t } = useTranslation();

  const { user } = useStoreState(
    (state) => state.dictionaries.dictionariesList
  );

  const requestFiltersSugestions = useStoreState(
    (state) => state.filters.requestFiltersSugestions
  );

  const setRequestFilter = useStoreActions(
    (state) => state.filters.setRequestFilter
  );

  useEffect(() => {
    if (applications) {
      const arr = [...applications];
      arr.sort((a, b) => {
        return a.date > b.date ? -1 : 1;
      });

      setPagination((prevState) => ({
        ...prevState,
        data: arr,
      }));
      handleSearch();
    }
  }, [applications]);

  const assignedToOptions = useMemo(() => {
    const arrayUsers = [];

    if (!user) return arrayUsers;

    Object.values(user).forEach((value) => {
      arrayUsers.push({
        value: value,
        text: value,
      });
    });

    return clearDuplicatesFromArray(arrayUsers);
  }, [user]);

  const handlePageClick = (event, { activePage }) => {
    setActivePage(activePage);
    const offset = (activePage - 1) * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  const handleSearchSurname = (event, data) => {
    let value = data.value;
    setSearchedClientName(value);
  };

  const handleSearchId = (event, data) => {
    let value = data.value;
    setSearchedId(value);
  };

  const handleSearchInvestment = (event, data) => {
    let value = data.value;

    setSearchedInvestment(value);
  };

  const handleSearchDeveloper = (event, data) => {
    let value = data.value;
    setSearchedDeveloper(value);
  };

  const handleSearchStatus = (event, data) => {
    let value = data.value;
    setSearchedStatus(value);
  };

  const handleSearchUser = (event, data) => {
    let value = data.value;
    setSearchedUser(value);
  };

  const handleSearchSendAfter = (event, data) => {
    let value = data.value;
    setSearchedSendAfter(value);
  };

  const handleSearchSendBefore = (event, data) => {
    let value = data.value;
    setSearchedSendBefore(value);
  };

  const handleSearchStatusChangeBefore = (event, data) => {
    let value = data.value;
    setSearchedStatusChangeBefore(value);
  };

  const handleSearchCreatedBefore = (event, data) => {
    let value = data.value;
    setSearchedCreatedBefore(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };

  const handleSearch = () => {
    let result = [];

    setRequestFilter({
      searchedClientName,
      searchedId,
      searchedInvestment,
      searchedDeveloper,
      searchedStatus,
      searchedUser,
      searchedSendAfter,
      searchedSendBefore,
      searchedCreatedBefore,
      searchedStatusChangeBefore,
    });

    result = applications
      .filter((data) => {
        return (
          data?.client_name
            ?.toLowerCase()
            ?.search(searchedClientName.toLowerCase()) !== -1
        );
      })
      .filter((data) => {
        if (!searchedId || !data?.id) return true;
        return data?.id?.toString() === searchedId?.toString();
      })
      .filter((data) => {
        return (
          data?.investment_name
            ?.toLowerCase()
            ?.search(searchedInvestment.toLowerCase()) !== -1
        );
      })
      .filter((data) => {
        return (
          data?.developer_name
            ?.toLowerCase()
            ?.search(searchedDeveloper.toLowerCase()) !== -1
        );
      })
      .filter((data) => {
        if (searchedStatus === "All") {
          return data;
        } else {
          return (
            data?.status
              ?.toLowerCase()
              ?.search(searchedStatus.toLowerCase()) !== -1
          );
        }
      })
      .filter((data) => {
        const userLastAndFirstName = [
          data?.user_name_last,
          data?.user_name_first,
        ]
          ?.filter((el) => el)
          .join(" ")
          .toLowerCase();
        return (
          userLastAndFirstName
            ?.toLowerCase()
            ?.search(searchedUser.toLowerCase()) !== -1
        );
      });
    if (sortTypeName === "date_modified_down" || sortTypeName === "date_down") {
      result.sort((a, b) => {
        return a.date > b.date ? -1 : 1;
      });
    }
    if (sortTypeName === "date_modified_up" || sortTypeName === "date_up") {
      result.sort((a, b) => {
        return a.date < b.date ? -1 : 1;
      });
    }

    if (searchedSendAfter !== "") {
      result = result.filter((data) => {
        return data.date >= searchedSendAfter;
      });
    }

    if (searchedSendBefore !== "") {
      result = result.filter((data) => {
        return data.date <= searchedSendBefore;
      });
    }

    if (searchedCreatedBefore !== "") {
      result = result.filter((data) => {
        return data.date <= searchedCreatedBefore;
      });
    }

    if (searchedStatusChangeBefore !== "") {
      result = result.filter((data) => {
        return data.date_modified <= searchedStatusChangeBefore;
      });
    }
    setPagination((prevState) => ({
      ...prevState,
      data: result,
    }));
  };

  const handleClearSearch = () => {
    setSearchedClientName("");
    setSearchedId("");
    setSearchedInvestment("");
    setSearchedStatus("");
    setSearchedDeveloper("");
    setSearchedUser("");
    setSearchedSendBefore("");
    setSearchedSendAfter("");
    setSearchedStatusChangeBefore("");
    setSearchedCreatedBefore("");
    setRequestFilter({});
    const arr = [...applications];

    if (sortTypeName === "date_modified_down" || sortTypeName === "date_down") {
      arr.sort((a, b) => {
        return a.date > b.date ? -1 : 1;
      });
    }
    if (sortTypeName === "date_modified_up" || sortTypeName === "date_up") {
      arr.sort((a, b) => {
        return a.date < b.date ? -1 : 1;
      });
    }

    setPagination((prevState) => ({
      ...prevState,
      data: arr,
    }));
  };

  const handleSortAsc = (arr) => {
    if (!arr) return [];
    return arr.sort((a, b) => (a.text > b.text ? 1 : -1));
  };

  const handleSortDownDown = (value) => {
    const arr = [...pagination.data];
    if (value === "date_modified") {
      arr?.forEach((el) => {
        if (el.date_modified === null) el.date_modified = el.date;
      });
    }
    arr.sort((a, b) => {
      return a[value] > b[value] ? -1 : 1;
    });
    setPagination((prevState) => ({
      ...prevState,
      data: arr,
    }));
  };
  const handleSortUpUp = (value) => {
    const arr = [...pagination.data];
    if (value === "date_modified") {
      arr?.forEach((el) => {
        if (el.date_modified === null) el.date_modified = el.date;
      });
    }
    arr.sort((a, b) => {
      return a[value] < b[value] ? -1 : 1;
    });
    setPagination((prevState) => ({
      ...prevState,
      data: arr,
    }));
  };

  const handleSortController = (e, data) => {
    const { value } = data;
    setSortTypeName(value);
    if (value === "client_name") handleSortUpUp("client_name_last");
    if (value === "date_up") handleSortUpUp("date");
    if (value === "date_down") handleSortDownDown("date");
    if (value === "date_modified_down") handleSortDownDown("date_modified");
    if (value === "date_modified_up") handleSortUpUp("date_modified");
  };
  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      pageCount: Math.ceil(prevState.data?.length / prevState.numberPerPage),
      currentData: prevState.data.slice(
        pagination.offset,
        pagination.offset + pagination.numberPerPage
      ),
    }));
  }, [pagination.data, pagination.numberPerPage, pagination.offset]);

  return (
    <>
      <div style={{ minHeight: "calc(80vh + 1rem)" }}>
        {showMobileMenu && <Overlay onClose={() => setShowMobileMenu(false)} />}
        <Container>
          <main>
            <header className="sticky top-20 py-4 lg:pt-0 lg:pb-0 lg:top-20  z-20 bg-white w-screen lg:w-full">
              <form
                onSubmit={handleSubmit}
                className={cs(
                  "flex flex-col items-center  absolute w-screen lg:w-full bg-white lg:static lg:justify-start lg:border-t-0 lg:py-0 transition-all transform  lg:transform -translate-y-0 duration-500",
                  { "-translate-y-mobileClientSearchHeight": !showMobileMenu }
                )}
              >
                <div className="w-full  flex flex-col lg:flex-row items-center lg:justify-start flex-wrap">
                  <Input
                    className="h-12 mx-2 mb-2 w-56 lg:mt-4"
                    placeholder={`${t("First name")}, ${t("Last name")}`}
                    fluid={true}
                    type="text"
                    onChange={handleSearchSurname}
                    value={searchedClientName}
                  />

                  <Input
                    className="h-12 mx-2 mb-2 w-32 lg:mt-4"
                    placeholder="Id"
                    fluid={true}
                    type="text"
                    onChange={handleSearchId}
                    value={searchedId}
                  />
                  <Input
                    className="w-56 mx-2 mb-2 h-12 lg:mt-4"
                    label={`${t("Post date")} ${t("from")}`}
                    labelPosition={"left corner"}
                    fluid={true}
                    type="date"
                    size={"small"}
                    onChange={handleSearchSendAfter}
                    value={searchedSendAfter}
                  />
                  <Input
                    className="w-56 mx-2 mb-2 h-12 lg:mt-4"
                    label={`${t("Post date")} ${t("to")}`}
                    labelPosition={"left corner"}
                    fluid={true}
                    type="date"
                    size={"small"}
                    onChange={handleSearchSendBefore}
                    value={searchedSendBefore}
                  />
                  <Dropdown
                    className="h-12 mx-2  mb-2 lg:mt-4"
                    placeholder={t("Investment")}
                    fluid={false}
                    search={true}
                    selection={true}
                    onChange={handleSearchInvestment}
                    options={handleSortAsc(
                      requestFiltersSugestions?.investments
                    )}
                    value={searchedInvestment}
                    clearable
                    selectOnBlur={false}
                  />

                  <Dropdown
                    className="h-12 mx-2 my-2   lg:mt-4"
                    placeholder={t("Status")}
                    fluid={false}
                    search={true}
                    selection={true}
                    onChange={handleSearchStatus}
                    options={handleSortAsc(requestFiltersSugestions?.status)}
                    value={searchedStatus}
                    clearable
                    selectOnBlur={false}
                  />

                  <Input
                    className="w-56 mx-2 h-12 mb-2 lg:mt-4 "
                    placeholder={t("Created to")}
                    labelPosition={"left corner"}
                    fluid={true}
                    type="date"
                    size={"small"}
                    onChange={handleSearchCreatedBefore}
                    value={searchedCreatedBefore}
                  />
                  <Input
                    className="w-56 mx-2 h-12 mb-2 lg:mt-4  "
                    label={t("Status change date to")}
                    labelPosition={"left corner"}
                    fluid={true}
                    type="date"
                    size={"small"}
                    onChange={handleSearchStatusChangeBefore}
                    value={searchedStatusChangeBefore}
                  />
                  <div className="hidden lg:flex  mb-2 lg:mt-4 ">
                    <Dropdown
                      className="h-12 mx-2"
                      placeholder={t("Developer")}
                      fluid={false}
                      search={true}
                      selection={true}
                      onChange={handleSearchDeveloper}
                      options={handleSortAsc(
                        requestFiltersSugestions?.developers
                      )}
                      value={searchedDeveloper}
                      clearable
                      selectOnBlur={false}
                    />
                    <div className="w-48">
                      <Dropdown
                        className="h-12 mx-2 hidden lg:block"
                        placeholder={t("Agent")}
                        fluid={true}
                        search={true}
                        selection={true}
                        onChange={handleSearchUser}
                        options={handleSortAsc(assignedToOptions)}
                        value={searchedUser}
                        clearable
                        selectOnBlur={false}
                        noResultsMessage={t("No results found")}
                      />
                    </div>
                  </div>
                  <div className="mx-2 justify-center items-center">
                    <button
                      className="leading-5 flex items-center py-3 px-4"
                      type="button"
                      onClick={handleClearSearch}
                    >
                      <X size={14} className="mr-2" />
                      {t("Clear")}
                    </button>
                  </div>
                  <div className="mx-2">
                    <button
                      className="leading-5 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                      type="submit"
                      onClick={() => setShowMobileMenu(false)}
                    >
                      {t("Search")}
                    </button>
                  </div>
                  <div className="ml-auto mr-0 py-4 flex items-center justify-center lg:justify-between ">
                    <div className="flex flex-col lg:flex-row"></div>
                    <div className="my-2 mx-2 md:my-0 flex  items-center hidden lg:inline-flex">
                      <div>
                        <p className="mx-2 text-xs lg:text-base">
                          {t("Sort by")}:{" "}
                        </p>
                      </div>
                      <div className="text-xs lg:text-base my-2 md:my-0">
                        <Dropdown
                          className="border-primse-green h-12"
                          style={{ display: "flex", alignItems: "center" }}
                          // placeholder={t("Choose")}
                          fluid={false}
                          selection={true}
                          defaultValue={sortTypeName}
                          options={[
                            {
                              key: `${t("Last name")}`,
                              text: `${t("Last name")}`,
                              value: "client_name",
                            },
                            {
                              key: `${t("Creation date ascending")}`,
                              text: `${t("Creation date ascending")}`,
                              value: "date_up",
                            },
                            {
                              key: `${t("Creation date descending")}`,
                              text: `${t("Creation date descending")}`,
                              value: "date_down",
                            },
                            {
                              key: `${t("Status change date ascending")}`,
                              text: `${t("Status change date ascending")}`,
                              value: "date_modified_up",
                            },
                            {
                              key: `${t("Status change date descending")}`,
                              text: `${t("Status change date descending")}`,
                              value: "date_modified_down",
                            },
                          ]}
                          onChange={(event, data) =>
                            handleSortController(event, data)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="lg:hidden flex justify-start pt-6 pb-2 mbx-2 sticky top-20 z-20 bg-white"
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                >
                  <div className="text-primse-green flex items-center">
                    {showMobileMenu && <ChevronUp size={24} />}
                    {!showMobileMenu && <ChevronDown size={24} />}
                    <BiSearchAlt2 size={24} />
                  </div>
                </div>
              </form>
            </header>
            <div className="hidden lg:flex w-full items-center justify-center lg:justify-start">
              <div className="mx-2">
                <h3 className="text-xl font-bold">
                  {t("Search results")}:{" "}
                  <span className="text-primse-green">
                    {pagination.data?.length}
                  </span>
                </h3>
              </div>
            </div>

            <div className="p-4 mt-6 border rounded-xl w-full flex flex-col lg:hidden">
              {pagination.currentData?.map(
                ({
                  date,
                  client_name,
                  user_name,
                  investment_name,
                  developer_name,
                  status,
                  dateTill,
                  uuid,
                  client_name_first,
                  client_name_last,
                  user_name_first,
                  user_name_last,
                  date_modified,
                }) => (
                  <RequestsItemMobile
                    key={uuid}
                    data={{
                      date,
                      client_name,
                      user_name,
                      investment_name,
                      developer_name,
                      status,
                      dateTill,
                      uuid,
                      client_name_first,
                      client_name_last,
                      user_name_first,
                      user_name_last,
                      date_modified,
                    }}
                  />
                )
              )}
            </div>
            <table className="w-full hidden lg:table">
              <thead className="bg-white font-bold uppercase sticky top-56 text-xs">
                <tr className="border-b h-14 items-center ">
                  <th className="px-2 text-left   bg-white ">
                    <div className="flex items-center">id</div>
                  </th>
                  <th className="px-2 text-left  bg-white ">
                    <div className="flex items-center">
                      {t("Creation date")}
                    </div>
                  </th>
                  <th className="px-2 text-left bg-white ">
                    <div className="flex items-center">{t("Client")}</div>
                  </th>
                  <th className="px-2 text-left bg-white ">
                    <div className="flex items-center">{t("Agent")}</div>
                  </th>
                  <th className="px-2 text-left bg-white ">{t("Phone")}</th>
                  <th className="px-2 text-left bg-white ">{t("E-mail")}</th>
                  <th className="px-2 text-left bg-white ">
                    {t("The investment")}
                  </th>
                  <th className="px-2 text-left bg-white ">{t("Developer")}</th>
                  <th className="px-2 text-left bg-white ">
                    <div className="flex items-center">{t("Status")}</div>
                  </th>
                  <th className="px-2 text-left bg-white ">
                    {t("Conditions")}
                  </th>
                  <th className="px-2 text-left bg-white ">
                    <div className="flex items-center">
                      {t("Change status")}
                    </div>
                  </th>
                  <th className="px-2 text-left bg-white ">
                    <div className="flex items-center">
                      {t("Decision until")}
                    </div>
                  </th>
                  <th className="px-2 text-left bg-white "></th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {pagination.currentData?.map(({ ...restProps }) => (
                  <RequestsSingleItem
                    key={restProps.uuid}
                    {...restProps}
                    handleRefresh={handleRefresh}
                  />
                ))}
              </tbody>
            </table>
            {pagination.data?.length > 20 && (
              <div className="py-4 mb-4">
                <Pagination
                  boundaryRange={0}
                  siblingRange={1}
                  prevItem={false}
                  nextItem={false}
                  activePage={activePage}
                  totalPages={pagination.pageCount}
                  onPageChange={handlePageClick}
                />
              </div>
            )}
          </main>
        </Container>
      </div>
      <NewsSection />
    </>
  );
};

export default RequestsView;

RequestsView.propTypes = {
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      ClientId: PropTypes.string,
      UserID: PropTypes.string,
      client_name: PropTypes.string,
      client_name_first: PropTypes.string,
      client_name_last: PropTypes.string,
      date: PropTypes.string,
      dateTill: PropTypes.string,
      developer_name: PropTypes.string,
      developer_uuid: PropTypes.string,
      id: PropTypes.string,
      investment_name: PropTypes.string,
      investment_uuid: PropTypes.string,
      status: PropTypes.string,
      user_name: PropTypes.string,
      user_name_first: PropTypes.string,
      user_name_last: PropTypes.string,
      uuid: PropTypes.string,
    })
  ),
};

// ApplicationsView.defaultProps = {};
